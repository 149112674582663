// We need to import the CSS so that webpack will load it.
// The MiniCssExtractPlugin is used to separate it out into
// its own CSS file.
import "../css/app.scss"

// webpack automatically bundles all modules in your
// entry points. Those entry points can be configured
// in "webpack.config.js".
//
// Import deps with the dep name or local files with a relative path, for example:
//
//     import {Socket} from "phoenix"
//     import socket from "./socket"
//
import "phoenix_html"
import {Socket} from "phoenix"
import NProgress from "nprogress"
import {LiveSocket} from "phoenix_live_view"

let csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")
let liveSocket = new LiveSocket("/live", Socket, {params: {_csrf_token: csrfToken}})

// Show progress bar on live navigation and form submits
window.addEventListener("phx:page-loading-start", info => NProgress.start())
window.addEventListener("phx:page-loading-stop", info => NProgress.done())

// connect if there are any LiveViews on the page
liveSocket.connect()

// expose liveSocket on window for web console debug logs and latency simulation:
// >> liveSocket.enableDebug()
// >> liveSocket.enableLatencySim(1000)
window.liveSocket = liveSocket

// Site custom
window.showModal = function(elem) {
  UIkit.dropdown('#quick_search').hide();
  UIkit.modal(elem).show();
}

let dotnavFix = document.querySelectorAll('.uk-dotnav li a')
dotnavFix.forEach((el) => {
  el.setAttribute('aria-label', 'dotnav link');
})

UIkit.util.on('#tm-sections', 'beforeshow', () => {
  UIkit.dropdown('#tm-page-dropdown').hide()
})

// let notifications = document.querySelectorAll('template[data-notification]')
// notifications.forEach(function(notification) {
//   UIkit.notification(notification.dataset.message, {
//     pos: 'bottom-center',
//     status: notification.dataset.status
//   })
// })

// (function() {
// })()
